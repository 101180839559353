import Vue from 'vue'
import VueRouter, {RouteConfig} from 'vue-router'
import store from '@/store';
import HubResourcesPage from '../views/HubResourcesPage/index.vue';
import AccessDeniedError from '../views/ErrorPages/AccessDeniedError.vue';
import CommunityPage from '../views/CommunityPage/index.vue';
import FAQPage from '../views/Help&About/FAQPage/index.vue';
import TutorialsPage from '../views/Help&About/TutorialsPage/index.vue';
import HubResourceDetailsPage from '@/views/HubResourceDetailsPage/index.vue';
import Roadmap from '@/views/Help&About/RoadmapPage/index.vue';
import SettingsPage from '@/views/SettingsPage/index.vue';
import ResourceNotFound from '@/views/ErrorPages/ResourceNotFound.vue';
import SavedSearchesPage from '../views/SavedSearchesPage/index.vue';
import MyResourcesPage from '../views/MyResourcesPage/index.vue';
import MyResourceDetailsPage from '../views/MyResourceDetailsPage/index.vue';
import ResourceEditorPage from '../views/ResourceEditorPage/index.vue';
import {COLLECTION_SORT_OPTIONS, SEARCH_CONTEXT} from '@/constants';
import ResourceEditorDetailsPage from '../views/ResourceEditorDetailsPage/index.vue';
import MyCollections from '@/views/MyCollectionsPage/index.vue'
import CollectionBoardPage from '@/views/CollectionBoardPage/index.vue'
import RCEAdminPage from '@/views/RCEAdminPage/index.vue'
import GenericError from '@/views/ErrorPages/GenericError.vue'

Vue.use(VueRouter);

// Route Config
const routes: Array<RouteConfig> = [
  {
    path: '/',
    meta: {
      protected: true,
      keepAlive: true,
    },
    redirect: {
      name: 'HubResourcesPage'
    }
  },
  {
    path: '/home',
    name: 'HubResourcesPage',
    component: HubResourcesPage,
    meta: {
      protected: true,
      keepAlive: true,
      title: 'Hub Resources - Universal Resource Hub'
    },
    async beforeEnter(to, from, next) {
      store.commit('SET_COLLECTION_VIEW', false);
      await store.dispatch('metadata/fetchFiltersMetadata');
      store.commit('SET_SEARCH_CONTEXT', SEARCH_CONTEXT.HUB);
      const allowAccess = localStorage.getItem('allow_access');
      if (allowAccess === 'false') {
        next('/denied');
      } else {
        next();
      }
    }
  },
  {
    path: '/detail/:id',
    name: 'HubResourceDetailsPage',
    component: HubResourceDetailsPage,
    meta: {
      protected: true,
      keepAlive: false,
      title: 'Resource Details - Universal Resource Hub'
    },
    async beforeEnter(to, from, next) {
      await store.dispatch('patchViews', to.params.id);
      next()
    }
  },
  {
    path: '/saved-searches',
    name: 'SavedSearchesPage',
    component: SavedSearchesPage,
    meta: {
      protected: true,
      title: 'Saved Searches - Universal Resource Hub'
    },
    async beforeEnter(to, from, next) {
      if (Object.keys(store.state.metadata.filtersMetadata).some(k => store.state.metadata.filtersMetadata[k].length === 0)) {
        await store.dispatch('metadata/fetchFiltersMetadata');
        next();
      } else {
        next();
      }
    }
  },
  {
    path: '/myresources',
    name: 'MyResourcesPage',
    component: MyResourcesPage,
    meta: {
      protected: true,
      keepAlive: true,
      title: 'My Resources - Universal Resource Hub'
    },
    async beforeEnter(to, from, next) {
      store.commit('SET_SEARCH_CONTEXT', SEARCH_CONTEXT.MY_RESOURCES);
      if (from.name) {
        if (!store.state.myResources.searchMode) {
          // because this view is keepAlive and we want to fetch data each time the route is switched,
          // therefore to call the APIs in beforeEnter(), instead of in the component's mounted().
          await store.dispatch('myResources/fetchResources', 'createdByMe');
          await store.dispatch('myResources/fetchResources', 'sharedWithMe');
          await store.dispatch('myResources/fetchResources', 'sharedByMe');
        }
      }
      next();
    }
  },
  {
    path: '/myresources/:id',
    name: 'MyResourceDetailsPage',
    component: MyResourceDetailsPage,
    meta: {
      protected: true,
      title: 'My Resources - Universal Resource Hub'
    },
    async beforeEnter(to, from, next) {
      if (!store.getters['users/userProfile']) {
        await store.dispatch('users/fetchUserProfile');
      }
      if (to.params.id === 'new') {
        // to create a new resource, first display Edit
        store.commit('myResources/SET_EDIT_MODE', true);
        next();
        return;
      }
      // in ResourceCard::openDetailsPage(), an api call is fired to fetch the active resource details
      let resourceDetails = store.state.myResources.resourceDetails;
      if (!resourceDetails || resourceDetails?.record?.id !== to.params.id) {
        resourceDetails = await store.dispatch('myResources/fetchResourceDetails', {id: to.params.id});
        if (!resourceDetails) {
          store.commit('errors/SET_ERROR_TYPE', 'resourceNotFound');
          next('/notfound');
          return;
        }
      }

      if (resourceDetails?.misc?.subSource === 'tutorial') {
        next();
        return;
      }

      /*      const versionHistory = await store.dispatch('myResources/getVersionHistory');
            let isDraft;
            if (versionHistory[0]?.isDraft) {
              isDraft = 'true';
            }*/

      const userAccess = [...resourceDetails.userAccess.owner, ...resourceDetails.userAccess.editor, ...resourceDetails.userAccess.viewer];
      const loggedUser = store.state.uid.toLowerCase();
      if (from.name !== 'TutorialsPage') {
        if (!userAccess.includes(loggedUser)) {
          store.commit('errors/SET_ERROR_TYPE', 'resourceNotFound');
          next('/notfound');
          return;
        }
        if (!from) {
          store.commit('myResources/SET_EDIT_MODE', false);
        }
        next();
      } else {
        next();
      }

    }
  },
  {
    path: '/mycollections',
    name: 'MyCollection',
    component: MyCollections,
    meta: {
      protected: true,
      keepAlive: true,
      title: 'My Collections'
    },
    async beforeEnter(to, from, next) {
      if (!store.getters['users/userProfile']) {
        await store.dispatch('users/fetchUserProfile');
      }

      const searchMode = store.state.myCollections.searchMode

      if (searchMode) {
        console.log('searchmode')
        // await store.dispatch('myCollections/fetchCollections', {
        //   page: 1,
        //   perPage: 16,
        //   access: 'all'
        // });
      } else {
        await store.dispatch('myCollections/fetchCollections', {
          access: 'followedbyme',
          page: 1
        });
        await store.dispatch('myCollections/fetchCollections', {
          access: 'createdbyme',
          page: 1
        });
        await store.dispatch('myCollections/fetchCollections', {
          access: 'sharedbyme',
          page: 1
        });
        await store.dispatch('myCollections/fetchCollections', {
          access: 'sharedwithme',
          page: 1
        });
      }
      next();
    }
  },
  {
    path: '/collection/:id',
    name: 'CollectionBoardPage',
    component: CollectionBoardPage,
    meta: {
      protected: true,
      title: 'Collection Details - Universal Resource Hub'
    },
    async beforeEnter(to, from, next) {
      setTimeout(async () => {
        // @TODO figure out how to remove the duplicated code in beforeRouteUpdate in CollectionBoardPage
        // Meanwhile please maintain the same code within
      if (!store.getters['users/userProfile']) {
        await store.dispatch('users/fetchUserProfile');
      }
      const collectionDetails = await store.dispatch('myCollections/fetchCollection', {
        ...to.params,
        hubCollection: to.query.rce,
        rce: to.query.rce,
        viewLog: !to.query.rce || to.query.rce === 'false'
      });
      if (collectionDetails) {
        if (from.name === 'HubResourcesPage' || store.state.myCollections.resourceSorting === '') {
          // if sequence order is set sort resources accordingly
          store.commit('myCollections/SET_RESOURCE_SORTING',
            collectionDetails.record.isLearningSequence ? COLLECTION_SORT_OPTIONS.SEQUENCE : COLLECTION_SORT_OPTIONS.UPDATED_ASC
          )
        }
        await store.dispatch('myCollections/getCollectionNotification', {
          collectionId: to.params.id,
          viewmode: to.query.rce === 'true' ? 'rceactivity': 'urhcolactivity'
        })
      } else {
        store.commit('errors/SET_ERROR_TYPE', 'resourceNotFound');
        next('/notfound');
        return;
      }
      next()
      }, 1000)
    }
  },
  {
    path: '/resourceeditor',
    name: 'ResourceEditorPage',
    component: ResourceEditorPage,
    props: true,
    meta: {
      protected: true,
      title: 'Resource Editor - Universal Resource Hub'
    },
    async beforeEnter(to, from, next) {
      /**
       * @approle if user doesnt have appRole of author or publisher, redirect them to home page
       */

      const userProfile = store.getters['users/userProfile']

      if (userProfile?.appRole && userProfile?.appRole.length > 0) {
        next()
        return
      }
      next('/')
    }
  },
  {
    path: '/resourceeditor/:id',
    name: 'ResourceEditorDetailsPage',
    component: ResourceEditorDetailsPage,
    props: true,
    meta: {
      protected: true,
      title: 'Resource Editor - Universal Resource Hub'
    },
    async beforeEnter(to, from, next) {
      store.commit('editor/SET_EDIT_MODE', false);
      setTimeout(async ()=>{
        const uid = store.state.uid;
        console.log(uid)
        const userProfile = store.getters['users/userProfile']
        if (!userProfile) {
          await store.dispatch('users/fetchUserProfile', uid);
        }
        if (!userProfile?.appRole && userProfile?.appRole < 0) {
          next('/')
          return
        } else {
          if (to.params.id === 'new') {
            // to create a new resource, first display Edit
            store.commit('editor/SET_RESOURCE_DETAILS', null); // resetting the active resource
            store.commit('editor/SET_EDIT_MODE', true);
            next();
            return;
          }
          const activeResource = await store.dispatch('editor/fetchResource', { id: to.params.id, application: to.params.application, source: to.params.source });
          const stateMachineForResource = await store.dispatch('editor/getStateMachineForResource', to.params.id)
          if (stateMachineForResource) {
            store.commit('editor/SET_STATE_MACHINE_FOR_RESOURCE', stateMachineForResource.data);
          }
          if (!activeResource) {
            store.commit('errors/SET_ERROR_TYPE', 'resourceNotFound');
            next('/notfound');
            return;
          }
        }

        next();
      }, 2000)

    }
  },
  {
    path: '/resourceeditoradmin',
    name: 'RCEAdminPage',
    component: RCEAdminPage,
    props: true,
    meta: {
      protected: true,
      title: 'Resource Editor - Universal Resource Hub'
    },
    async beforeEnter(to, from, next) {
      /**
       * @approle if user doesnt have appRole of author or publisher, redirect them to home page
       */

      const userProfile = store.getters['users/userProfile']

      if (userProfile?.appRole && userProfile?.appRole.length > 0) {
        next()
        return
      }
      next('/')
    }
  },
  {
    path: '/community',
    name: 'CommunityPage',
    component: CommunityPage,
    meta: {
      protected: true,
      title: 'Community - Universal Resource Hub'
    }
  },
  {
    path: '/faq',
    name: 'FAQPage',
    component: FAQPage,
    meta: {
      protected: true,
      title: 'FAQ - Universal Resource Hub'
    }
  },
  {
    path: '/tutorials',
    name: 'TutorialsPage',
    component: TutorialsPage,
    meta: {
      protected: true,
      title: 'Tutorials - Universal Resource Hub'
    }
  },
  {
    path: '/roadmap',
    name: 'Roadmap',
    component: Roadmap,
    meta: {
      protected: true,
      title: 'Road Map - Universal Resource Hub'
    }
  },
  {
    path: '/settings',
    name: 'Settings',
    component: SettingsPage,
    meta: {
      protected: true,
      title: 'Settings - Universal Resource Hub'
    }
  },
  {
    path: '/notfound',
    name: 'ResourceNotFound',
    component: ResourceNotFound,
    meta: {
      protected: true,
      title: 'Not Found - Universal Resource Hub'
    },
    beforeEnter(to, from, next) {
      let errorDetails;
      switch (store.state.errors.type) {
        case 'resourceNotFound':
        // case 'collectionNotFound':
          errorDetails = {
            heading: 'Resource not found',
            title: 'Sorry, this resource was not found',
            subtitle: 'We did not recognise this resource ID. Please navigate to the search page to find other great resources.',
            actionText: 'Go to Resource Hub',
            actionHandler: () => router.push('/home')
          }
          store.commit('errors/SET_ERROR_DETAILS', errorDetails);
          break;
        case 'pageNotFound':
          errorDetails = {
            heading: 'Page not found',
            title: "Sorry, this page doesn't seem to exist",
            subtitle: 'We did not recognise this URL. Please navigate to the home page to find other great content.',
            actionText: 'Go to Resource Hub',
            actionHandler: () => router.push('/home')
          }
          store.commit('errors/SET_ERROR_DETAILS', errorDetails);
          break;
        default:
          next('/home');
      }
      next();
    }
  },
  {
    path: '*',
    name: 'WrongPath',
    meta: {
      protected: true
    },
    beforeEnter(to, from, next) {
      store.commit('errors/SET_ERROR_TYPE', 'pageNotFound');
      next('/notfound');
    }
  },
  {
    path: '/denied',
    name: 'AccessDeniedError',
    component: AccessDeniedError,
    meta: {
      protected: true,
      title: 'Access Denied - Universal Resource Hub'
    }
  },
  {
    path: '/error/:id',
    name: 'GenericError',
    component: GenericError,
    meta: {
      protected: false,
      title: 'Error - Universal Resource Hub'
    }
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.params.savePosition) {
      return;
    }
    return {x: 0, y: 0}
  }
});

router.beforeEach((to, from, next) => {
  store.commit('SET_PREVIOUS_PATH', from.path);
  next();
});

router.afterEach((to, from) => {
  // pushing page name to data layer
  (window as any).dataLayer.push({
    'event': 'navigation',
    'page_name': to?.name
  });

  console.log('entered the route...');
  setTimeout(async ()=>{
    // enforcing qualtrics to load and run manually as automatic triggers didn't work
    // @TODO remove this code when we disable qualtrics survey in GTM
    // unload before re-loading and running the survey
    if ((window as any).QSI?.API) {
      console.log('QSI.API defined..');
      (window as any).QSI.API.unload();
    } else {
      console.log('QSI.API not defined..')
    }
    (window as any).QSI.API.load().done((window as any).QSI.API.run());
  }, 3000)


  document.title = to?.meta?.title;
  if (to.path === '/myresources/new') {
    document.title = 'New Resource - Universal Resource Hub';
  }
});

export default router;
